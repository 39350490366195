<template>
  <v-container style="padding-top: 8%" fluid>
    <v-row
      style="padding-bottom: 2%; padding-left: 15%; padding-right: 15%"
      :justify="isMobile ? '' : 'center'"
    >
      <v-col :cols="isMobile ? '12' : '9'">
        <h1 class="mb-6 about-title">{{ $t("about.title1") }}</h1>
        <p class="about-text">
          {{ $t("about.content1") }}
        </p>
      </v-col>
    </v-row>

    <v-row class="about-klover-with-a-k" :justify="isMobile ? '' : 'center'">
      <v-col :cols="isMobile ? '12' : '3'">
        <p class="about-klover-with-a-k-title">{{ $t("about.title2") }}</p>
      </v-col>
      <v-col :cols="isMobile ? '12' : '6'">
        <p class="about-text">
          {{ $t("about.content21")
          }}<a href="https://kermap.com/" target="_blank">Kermap</a>
          {{ $t("about.content22") }}
        </p>
      </v-col>
      <v-img
        v-if="!isMobile"
        :src="require('@/assets/images/Kermap_3D.jpg')"
        :lazy-src="require('@/assets/images/Kermap_3D.jpg')"
        max-height="50%"
        max-width="39%"
        contain
        eager
        style="position: absolute; bottom: 0; left: 0; z-index: 0"
      />
    </v-row>

    <v-row
      style="
        padding-top: 8%;
        padding-bottom: 8%;
        padding-left: 15%;
        padding-right: 15%;
        z-index: 3;
      "
      align="end"
      :justify="isMobile ? '' : 'center'"
    >
      <v-col :cols="isMobile ? '12' : '6'">
        <p class="about-klover-with-a-k-title pb-5">{{ $t("about.title3") }}</p>
        <p class="about-text">
          {{ $t("about.content31")
          }}<a href="https://www.nosvillesvertes.fr/" target="_blank"
            >Nos Villes Vertes</a
          >
          {{ $t("about.content32") }}
        </p>
      </v-col>
      <v-col :cols="isMobile ? '12' : '3'" style="position: relative">
        <v-img
          :lazy-src="require('@/assets/images/nosvillesvertes1.jpg')"
          :src="require('@/assets/images/nosvillesvertes1.jpg')"
          :max-width="isMobile ? '' : '20vw'"
          class="about-img-1 elevation-20"
          contain
        >
        </v-img>
        <v-img
          v-if="!isMobile"
          :lazy-src="require('@/assets/images/nosvillesvertes2.jpg')"
          :src="require('@/assets/images/nosvillesvertes2.jpg')"
          :max-width="isMobile ? '' : '20vw'"
          class="about-img-2 elevation-10"
          contain
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row
      :class="
        isMobile ? 'about-trees-div' : 'about-trees-div about-trees-div-img'
      "
      :justify="isMobile ? '' : 'center'"
      :align="isMobile ? '' : 'center'"
    >
      <v-col :cols="isMobile ? '12' : '9'">
        <h3 class="about-trees-title mb-5">
          <v-icon class="mr-5" :size="isMobile ? '35px' : '3vw'"
            >$vuetify.icon.tree_active</v-icon
          >
          {{ $t("about.title4") }}
        </h3>
        <p class="about-trees-text mt-4">
          {{ $t("about.content41")
          }}<a
            href="https://www.ipcc.ch/report/ar6/wg1/downloads/factsheets/IPCC_AR6_WGI_Regional_Fact_Sheet_Urban_areas.pdf"
            target="_blank"
            >{{ $t("about.content41-link") }}</a
          >
        </p>
        <p class="about-trees-text mt-4">
          {{ $t("about.content42")
          }}<a
            href="https://www.c40.org/press_releases/c40-mckinsey-focused-adaptation"
            target="_blank"
            >{{ $t("about.content42-link") }}</a
          >
          {{ $t("about.content43") }}
        </p>
      </v-col>
    </v-row>

    <v-row class="about-biggest-div" :justify="isMobile ? '' : 'center'">
      <v-col :cols="isMobile ? '12' : '9'">
        <h3 class="about-klover-focus-title">
          {{ $t("about.button-section.main-title") }}
        </h3>
        <h3 class="about-klover-focus-title mt-10">
          {{ $t("about.button-section.title") }}
        </h3>
        <div class="about-slider mt-6 mb-6">
          <button
            id="about-source"
            class="about-slider-button about-slider-button-active"
            @click="toggleContent($event)"
          >
            <p
              id="about-text-source"
              class="about-slider-button-text about-slider-button-text-active"
            >
              {{ $t("about.button-section.buttons.button1") }}
            </p>
          </button>
          <button
            id="about-indicators"
            class="about-slider-button"
            @click="toggleContent($event)"
          >
            <p id="about-text-indicators" class="about-slider-button-text">
              {{ $t("about.button-section.buttons.button2") }}
            </p>
          </button>
          <button
            id="about-study"
            class="about-slider-button"
            @click="toggleContent($event)"
          >
            <p id="about-text-study" class="about-slider-button-text">
              {{ $t("about.button-section.buttons.button3") }}
            </p>
          </button>
        </div>
        <div v-if="display === 'source'">
          <h3 class="about-klover-focus-title mt-5 mb-7">
            {{ $t("about.button-section.source.title") }}
          </h3>
          <p class="about-text-long mb-3">
            {{ $t("about.button-section.source.content11")
            }}<a href="https://www.copernicus.eu/en" target="_blank">{{
              $t("about.button-section.source.content11-link")
            }}</a>
            {{ $t("about.button-section.source.content12") }}
          </p>
          <p class="about-text-long">
            {{ $t("about.button-section.source.content21")
            }}<a
              href="https://www.intelligence-airbusds.com/en/8692-pleiades"
              target="_blank"
              >{{ $t("about.button-section.source.content21-link") }}</a
            >
            {{ $t("about.button-section.source.content22")
            }}<a href="https://cnes.fr/" target="_blank">{{
              $t("about.button-section.source.content22-link")
            }}</a>
            {{ $t("about.button-section.source.content23") }}
          </p>
        </div>
        <div v-if="display === 'indicators'">
          <h3 class="about-klover-focus-title mt-5 mb-7">
            {{ $t("about.button-section.indicators.title") }}
          </h3>
          <p class="about-text-long mb-3">
            {{ $t("about.button-section.indicators.content1") }}
          </p>
          <ul class="mb-3">
            <li>{{ $t("about.button-section.indicators.list1") }}</li>
            <li>{{ $t("about.button-section.indicators.list2") }}</li>
            <li>{{ $t("about.button-section.indicators.list3") }}</li>
            <li>{{ $t("about.button-section.indicators.list4") }}</li>
            <li>{{ $t("about.button-section.indicators.list5") }}</li>
            <li>{{ $t("about.button-section.indicators.list6") }}</li>
          </ul>
          <p class="about-text-long">
            {{ $t("about.button-section.indicators.content21")
            }}<a href="https://openmaptiles.org/" target="_blank">{{
              $t("about.button-section.indicators.content21-link")
            }}</a>
            {{ $t("about.button-section.indicators.content22")
            }}<a href="https://www.openstreetmap.org/about" target="_blank">{{
              $t("about.button-section.indicators.content22-link")
            }}</a>
          </p>
        </div>
        <div v-if="display === 'study'">
          <h3 class="about-klover-focus-title mt-5 mb-7">
            {{ $t("about.button-section.study.title") }}
          </h3>
          <p class="about-text-long mb-3">
            {{ $t("about.button-section.study.content1") }}
          </p>
          <p class="about-text-long mb-3">
            {{ $t("about.button-section.study.content2") }}
          </p>
          <p class="about-text-long">
            {{ $t("about.button-section.study.content3") }}
          </p>
        </div>
        <h3 class="about-klover-focus-title mt-15 mb-6">
          {{ $t("about.climate.title") }}
        </h3>
        <p class="about-text-long">
          {{ $t("about.climate.content11")
          }}<a href="https://power.larc.nasa.gov/" target="_blank">{{
            $t("about.climate.content11-link")
          }}</a>
          {{ $t("about.climate.content12")
          }}<a href="https://public.wmo.int/fr" target="_blank">{{
            $t("about.climate.content12-link")
          }}</a>
          {{ $t("about.climate.content13")
          }}<a href="http://www.drias-climat.fr/" target="_blank">{{
            $t("about.climate.content13-link")
          }}</a>
        </p>
        <v-btn
          class="mt-5"
          outlined
          large
          rounded
          color="#264a8d"
          style="text-transform: inherit"
          @click="toggleLongClimate()"
        >
          {{ $t("about.climate.know-more") }}
          <v-icon v-if="!longClimate" class="pl-2">mdi-chevron-right</v-icon>
          <v-icon v-else class="pl-2">mdi-chevron-down</v-icon>
        </v-btn>

        <div v-if="longClimate">
          <h3 class="about-klover-focus-title mt-5 mb-5">
            {{ $t("about.climate.more-section.bloc1.title") }}
          </h3>
          <ul>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc1.list1") }}
            </li>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc1.list2") }}
            </li>
          </ul>
          <p class="about-text-long mt-5">
            {{ $t("about.climate.more-section.bloc1.content") }}
          </p>

          <h3 class="about-klover-focus-title mt-15 mb-5">
            {{ $t("about.climate.more-section.bloc2.title") }}
          </h3>
          <p class="about-text-long">
            {{ $t("about.climate.more-section.bloc2.content") }}
          </p>
          <h3 class="about-klover-focus-subtitle mt-15 mb-5">
            {{ $t("about.climate.more-section.bloc2.list-title") }}
          </h3>
          <ul>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc2.list1") }}
            </li>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc2.list2") }}
            </li>
          </ul>

          <h3 class="about-klover-focus-subtitle mt-15 mb-5">
            {{ $t("about.climate.more-section.bloc3.list-title") }}
          </h3>
          <ul>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc3.list1") }}
            </li>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc3.list2") }}
            </li>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc3.list3") }}
            </li>
          </ul>

          <h3 class="about-klover-focus-subtitle mt-15 mb-5">
            {{ $t("about.climate.more-section.bloc4.list-title") }}
          </h3>
          <ul>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc4.list1") }}
            </li>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc4.list2") }}
            </li>
            <li class="about-text-long">
              {{ $t("about.climate.more-section.bloc4.list3") }}
            </li>
          </ul>
        </div>

        <h3 class="about-klover-focus-title mt-15 mb-6">
          {{ $t("about.title5") }}
        </h3>
        <p class="about-text-long">
          {{ $t("about.content51") }}
        </p>
        <p class="about-text-long">
          {{ $t("about.content52") }}
        </p>
        <a target="__blank" href="mailto:klover@kermap.com"
          >klover@kermap.com</a
        >
        <h3 class="about-support mt-15 mb-6">{{ $t("about.support") }}</h3>
        <v-row>
          <v-img
            :src="require('@/assets/images/cnes_logo.png')"
            contain
            max-height="100px"
            max-width="150px"
          ></v-img>
          <v-img
            :src="require('@/assets/images/airbus_logo.png')"
            contain
            max-height="100px"
            max-width="150px"
          ></v-img>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  metaInfo() {
    var result;
    var resultTitle;
    if (window.location.pathname === "/propos") {
      resultTitle = "À Propos - Klover";
      result = [
        {
          name: "title",
          content: "Klover, c’est quoi ?",
        },
        {
          name: "description",
          content:
            "Klover informe sur l’espace occupé par la végétation en analysant les images satellite de 24 grandes villes de la planète,",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/propos",
        },
        {
          property: "og:title",
          content: "Klover, c’est quoi ?",
        },
        {
          property: "og:description",
          content:
            "Klover informe sur l’espace occupé par la végétation en analysant les images satellite de 24 grandes villes de la planète,",
        },
        { property: "og:image", content: this.$t("meta.home.img") },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/propos",
        },
        {
          property: "twitter:title",
          content: "Klover, c’est quoi ?",
        },
        {
          property: "twitter:description",
          content:
            "Klover informe sur l’espace occupé par la végétation en analysant les images satellite de 24 grandes villes de la planète,",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Klover-home-card.jpg",
        },
      ];
    } else {
      resultTitle = "About - Klover";
      result = [
        {
          name: "title",
          content: "What is Klover ?",
        },
        {
          name: "description",
          content:
            "Klover is a platform showing how much room vegetation gets in 24 big cities around the world, using satellite images.",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/about",
        },
        {
          property: "og:title",
          content: "What is Klover ?",
        },
        {
          property: "og:description",
          content:
            "Klover is a platform showing how much room vegetation gets in 24 big cities around the world, using satellite images.",
        },
        { property: "og:image", content: this.$t("meta.home.img") },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/about",
        },
        {
          property: "twitter:title",
          content: "What is Klover ?",
        },
        {
          property: "twitter:description",
          content:
            "Klover is a platform showing how much room vegetation gets in 24 big cities around the world, using satellite images.",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/Klover-home-card-en.jpg",
        },
      ];
    }
    return {
      title: resultTitle,
      meta: result,
    };
  },
  data() {
    return {
      isMobile: false,
      display: "source",
      longClimate: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800;
    });
  },
  methods: {
    toggleLongClimate() {
      this.longClimate = !this.longClimate;
    },
    toggleContent(evt) {
      if (
        evt.target.id === "about-source" ||
        evt.target.id === "about-text-source"
      ) {
        document
          .getElementById("about-indicators")
          .classList.remove("about-slider-button-active");
        document
          .getElementById("about-text-indicators")
          .classList.remove("about-slider-button-text-active");
        document
          .getElementById("about-study")
          .classList.remove("about-slider-button-active");
        document
          .getElementById("about-text-study")
          .classList.remove("about-slider-button-text-active");
        document
          .getElementById("about-source")
          .classList.add("about-slider-button-active");
        document
          .getElementById("about-text-source")
          .classList.add("about-slider-button-text-active");
        this.display = "source";
      } else if (
        evt.target.id === "about-indicators" ||
        evt.target.id === "about-text-indicators"
      ) {
        document
          .getElementById("about-indicators")
          .classList.add("about-slider-button-active");
        document
          .getElementById("about-text-indicators")
          .classList.add("about-slider-button-text-active");
        document
          .getElementById("about-study")
          .classList.remove("about-slider-button-active");
        document
          .getElementById("about-text-study")
          .classList.remove("about-slider-button-text-active");
        document
          .getElementById("about-source")
          .classList.remove("about-slider-button-active");
        document
          .getElementById("about-text-source")
          .classList.remove("about-slider-button-text-active");
        this.display = "indicators";
      } else {
        document
          .getElementById("about-indicators")
          .classList.remove("about-slider-button-active");
        document
          .getElementById("about-text-indicators")
          .classList.remove("about-slider-button-text-active");
        document
          .getElementById("about-study")
          .classList.add("about-slider-button-active");
        document
          .getElementById("about-text-study")
          .classList.add("about-slider-button-text-active");
        document
          .getElementById("about-source")
          .classList.remove("about-slider-button-active");
        document
          .getElementById("about-text-source")
          .classList.remove("about-slider-button-text-active");
        this.display = "study";
      }
    },
  },
};
</script>

<style scoped>
.about-title {
  font-weight: inherit;
  font-size: 3vw;
}

.about-text {
  font-size: 1vw;
  max-width: 30vw;
}

.about-text-long {
  font-size: 1vw;
}

.about-klover-with-a-k {
  position: relative;
  background-color: #c1d6e7;
  padding-top: 8%;
  padding-bottom: 8%;
  background-clip: border-box;
  padding-left: 15%;
  padding-right: 15%;
}

.about-klover-with-a-k-title {
  font-weight: bold;
  font-size: 1.5vw;
}

.about-visit-website-btn {
  text-transform: inherit;
}

.about-visit-website-btn-text {
  color: #082e6e;
}

.about-trees-div {
  background-color: #2c5ca8;
  padding: 8% 15%;
}

.about-trees-div-img {
  background: url("City/background-image/whytrees.jpg");
}

.about-trees-title {
  text-align: center;
  color: white;
  font-size: 1.5vw;
}

.about-trees-text {
  color: white;
  font-size: 1vw;
}

.about-biggest-div {
  padding: 8%;
  padding-left: 15%;
  padding-right: 15%;
}

.about-klover-focus-title {
  font-size: 1.5vw;
}

.about-slider {
  display: inline-flex;
  background-color: white;
  border-radius: 25px;
  flex-basis: auto;
}

.about-slider-button {
  padding: 3%;
  width: 150px;
}

.about-slider-button-text {
  text-align: center;
  color: #95adb9;
}

.about-slider-button-active {
  background-color: #ffd038;
  border-radius: 25px;
}

.about-slider-button-text-active {
  text-align: center;
  color: #17359b;
}

.about-support {
  font-size: 1vw;
}

.about-img-1 {
  border-radius: 10px;
  z-index: 1;
  top: 0;
}

.about-img-2 {
  border-radius: 10px;
  position: absolute;
  top: -40px;
  z-index: 0;
  left: 60px;
}

.about-klover-focus-subtitle {
  font-size: 1.2vw;
}

@media screen and (max-width: 800px) {
  .about-title {
    font-size: 24px;
  }

  .about-text {
    font-size: 16px;
    max-width: inherit;
  }

  .about-text-long {
    font-size: 16px;
  }

  .about-klover-focus-subtitle {
    font-size: 20px;
  }

  .about-klover-with-a-k-title {
    font-size: 20px;
  }

  .about-trees-title {
    font-size: 20px;
  }

  .about-trees-text {
    font-size: 16px;
  }

  .about-klover-focus-title {
    font-size: 24px;
  }

  .about-support {
    font-size: 16px;
  }

  .about-slider {
    max-width: inherit;
  }

  .about-slider-button-text {
    font-size: 16px;
  }
}
</style>
